export default function OurStory() {
    return (
        <div className='eucalyptus-bg'>
            <div className='text'>
                <h1>Our Story</h1>
                <p><span className='dialogue'>Luke:</span> The idea of working at FSY in summer of 2023 was so exciting to me, except for the fact that dancing is literally in the job description. I almost didn’t go until Brad Wilcox visited my roommate’s religion class and told all the students that one in five YSA that work at FSY meet their eternal companion there. That was enough to get me to brave a summer of dancing haha.</p>
                <p><span className='dialogue'>Taylor:</span> I was so excited for another summer of FSY! I worked for EFY/FSY for about 5 years. However, if someone would have told me I was going to meet the person I was going to marry while doing FSY this particular summer I probably would have laughed out loud. I went into the summer just so excited to be doing FSY and hoping I would find a cute boy but also telling myself it wasn’t going to happen. </p>
                <p><span className='dialogue'>Luke:</span> I met Taylor in Oklahoma in my second week of FSY. From the beginning I thought she was cute and had an adorable personality, but there were several obstacles in my way. One, she was older than me. Two, she had already graduated and had a big person job. And three, she was my boss. I was down to play up but I wasn’t sure if she was up to play down hahaha.</p>
                <p><span className='dialogue'>Taylor:</span> I arrived in Oklahoma early to get the session set up with my co-coordinator. We were so excited for the bus to arrive with all of our counselors and assistant coordinators (ACs). After getting everyone checked in we asked the ACs to hang back. I immediately found myself drawn to Luke and texted my friend that there was a cute AC. As a leadership team we all went out to dinner and I made it a point to sit next to Luke. However, I quickly learned he was a few or so years younger than me and didn’t think he would want to date me because I was older and had a big person job (I had been told before that I was intimidating).</p>
                <p><span className='dialogue'>Luke:</span> I had to start sending the signals to see how she reacted. I jumped on every assignment possible that she needed help with. It probably started becoming a little more obvious when I was always the most eager to volunteer even when the assignment was really late at night or really early in the morning. Soon, our entire team knew what was up except for Taylor and the other coordinator. The wrong people were picking up the right signals haha.</p>
                <p><span className='dialogue'>Taylor:</span> Once Luke started volunteering for things all the time and hanging out with me, I had my suspicions about what was going on. However, my doubtful side kept telling me I was wrong and he wasn’t interested. I decided to keep playing the role of just friends but I always did my best to sit with him at meals, walk with him to different activities, and even cut my breaks short to listen to him sing. I honestly thought we were just really good friends hahaha.</p>
                <p><span className='dialogue'>Luke:</span> During one of the dances, I was walking around talking to the kids sitting on the edges so I didn’t have to dance. I saw my role as the moral support haha. I hadn’t planned or even considered dancing that night, but when a slow song came on I somehow found myself approaching Taylor out of nowhere to ask her to dance. I could tell she was so scared, and this gave me hope. It meant she might be up to play down.</p>
                <p><span className='dialogue'>Taylor:</span> So I'm totally that super observant person who notices little things about the person I like… such as how they haven’t slow danced with anyone at the dances. So when Luke showed up literally out of thin air and was asking me to dance I was in complete shock and also SO nervous. The boy I had a crush on was asking me to dance and hadn’t danced with anyone else! This was great great great news! That night he even “accidentally” drank my slushie I was so  looking forward to, but used it as an opportunity to give me a hug so I wasn’t so mad. We spent that night talking about random things and just getting to know each other. But of course… I still wasn’t convinced he liked me.</p>
                <p><span className='dialogue'>Luke:</span> (Okay the slushie was a legit accident, the mango and the peach look the exact same, but it did prove to be a great reason to give her a hug hahaha) After a while she started to catch on with the help of some of our teammates. Taylor and I, as well as one of our other co-workers, were able to go early to the next session in Texas to set it up. We had some extra time on Saturday afternoon, and I asked her to go on a date with me. We went on a tiny but really expensive zipline, but it was worth the investment. We went on a walk afterwards and held hands for the first time.</p>
                <p><span className='dialogue'>Taylor:</span> Eventually one of our teammates had to tell me Luke was into me because I was clearly not getting all the hints he was supposedly sending. We somehow convinced my co-coordinator to let Luke come early with me and another teammate to Texas. It was so fun! He totally caught me off guard by asking me on a date… in the car… on our way to Texas… at the gas station. We had a lot of fun though! I felt so bad about the zipline because I knew it was expensive and it was very short so I tried to hype it up as much as possible. When he asked to hold my hand I was honestly in complete shock that this was happening. The guy I thought was cute from day one actually liked me too!! But I loved spending as much time as possible with him.</p>
                <p><span className='dialogue'>Luke:</span> (Okay so the gas station wasn’t that classy, but I didn’t have a lot to work with haha) By the end of our three weeks together, I knew I liked Taylor a lot, but I wasn’t sure how I’d do with a whole summer of long distance while working the busiest job I’d ever worked. I was honest with her about it and I think it scared her a little haha. But we talked every single day for the whole summer, video calling, marco poloing, texting, and voice calling during short breaks and late nights. By the time FSY was over in August, I knew I loved her. Even so, we resumed long distance as I did a semester at BYU-I in Rexburg, Idaho.</p>
                <p><span className='dialogue'>Taylor:</span> After our three weeks together I knew I liked this guy. We spent as much time as possible together and even sacrificed sleep so we could continue getting to know each other. When Luke told me he wasn’t sure about long distance all summer I started to panic. I was not going to let this be just a summer fling. But then our everyday talks became the highlight of my days. When we got back and Luke told me he loved me, not going to lie, I panicked again, and well, let's just say my response is one for the books. But after a while I knew I loved him too. Luke was able to finally convince me to go ring shopping and luckily I knew within minutes the style I wanted. Luke would try to throw me off so I didn’t know whether he had the ring or not. Once Thanksgiving had come and gone without a proposal though I was starting to get a little frustrated. I was just so excited and couldn’t wait. Come to find out the ring wasn’t done yet, so I told myself it was going to be Christmas break. Well two days before the break I was in a meeting after school. Little did I know this meeting was set up to get me out of my classroom so Luke could come in and get it all set up. After my meeting I walked back to my classroom to find the door closed. I was so confused. But when I opened the door I saw the desks in the shape of a heart, rose petals, and a video waiting to be played. This was it. The day I had been waiting for and it was PERFECT! I was completely surprised as I’m sure Luke could tell by the paint, glitter, notebooks, and paper all over the desks. But I wouldn’t trade it for anything.</p>
                <p><span className='dialogue'>Luke:</span> After Christmas break I was able to move down close to Taylor in Utah as I started my web development internship for FamilySearch. We’ll be down here until my internship is over and Taylor is out of school, and then we’ll head back up to Rexburg until I graduate. We’re so excited to start our eternal journey together, she’s the one I’ve been waiting and searching for for so long. I love her so much, she’s sweet sweet sweet!</p>
                <p><span className='dialogue'>Taylor:</span> After Christmas Luke moved down here close to me. The first time ever that we weren’t doing long distance and I was so excited. We’ll be down here until we move to the tundra… I mean Rexburg. I am so grateful for Luke and everything he does for me. He makes me laugh and smile and I’m excited for all of our adventures ahead. I love him!</p>
            </div>
        </div>
    )
  }